import translate from './../services/translation.json';
import './../assets/scss/aboutUs.scss';
import aboutImg from './../assets/images/about-img.png';
import GetInTouch from "../components/getInTouch/getInTouch";
import OurClients from "../components/ourClients/ourClients";
const About = ({lang}) => {
  return (
    <div className="about-us page-container">
      <div className='about-us-container mw-1200'>
        <div className='about-us-container-details padded-container'>
          <h1 className='blue'>
            {translate[lang].about_page.who_we_are}
          </h1>
          <p className='who-we-are-desc'>{translate[lang].about_page.who_we_are_desc}</p>
        </div>
        <div className='about-us-container-img'><img src={aboutImg} alt="about-us"/></div>
      </div>
      <OurClients lang={lang}/>
      <div className='about-us-more '>
        <p className='mw-1200'>{translate[lang].about_page.who_we_are_desc_1}</p>
      </div>
      <GetInTouch lang={lang}/>
    </div>
  )
}

export default About