
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
//import pages
import Header from './components/header/header';
import Home from './pages/home';
import About from './pages/about';
import Portfolio from './pages/portfolio';
import Services from './pages/services';
import Contact from './pages/contactUs';
import Footer from './components/footer/footer'
//import main styles
import "./App.scss";

function App() {

  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en')
  useEffect(()=> {
    localStorage.setItem('lang', lang)
  },[lang])
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Header lang={lang} setLang={setLang}/>
      <Routes>
        <Route path="/" element={<Home lang={lang}/>}/>
        <Route path="/about-us" element={<About lang={lang}/>}/>
        <Route path="/portfolio" element={<Portfolio lang={lang}/>}/>
        <Route path="/contact-us" element={<Contact lang={lang}/>}/>
        <Route path="/services" element={<Services lang={lang}/>}/>
      </Routes>
      <Footer lang={lang}/>
    </BrowserRouter>
  );
}

export default App;
