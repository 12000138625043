import { Link } from "react-router-dom";
import translate from "../../services/translation.json";
import './footer.scss';
import whiteLogo from './../../assets/images/Logo White.svg';
import callIcon from './../../assets/images/call-icon.svg';
import emailIcon from './../../assets/images/email-icon.svg';
import locationIcon from './../../assets/images/location-icon.svg';
import fbIcon from './../../assets/images/fb-icon.svg';
import instaIcon from './../../assets/images/instagram.svg';
import linkedinIcon from './../../assets/images/linkedin-icon.svg';

const Footer = ({lang}) => {
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <Link to={'/'} className="logo-container">
          <img src={whiteLogo} alt='footer-logo'/>
        </Link>
        <div className="information-section">
          <p className="section-title">
            {translate[lang].footer.information}
          </p>
          <div className="footer-links">
            <Link to={'/about-us'}>{translate[lang].footer['about-us']}</Link>
            <Link to={'/services'}>{translate[lang].footer.services}</Link>
          </div>
        </div>
        <div className="quick-links-section">
          <p className="section-title">
            {translate[lang].footer.quick_links}
          </p>
          <div className="footer-links">
            {/* <Link to={'/portfolio'}>{translate[lang].footer.portfolio}</Link> */}
            <Link to={'/contact-us'}>{translate[lang].footer.contact_us}</Link>
          </div>
        </div>
        <div className="contact-us-section">
          <p className="section-title">
            {translate[lang].footer.contact_us}
          </p>
          <div className="contact-details">
            <div><img src={callIcon} alt='call-icon'/><span>+41 78 793 79 83</span></div>
            <div><img src={emailIcon} alt='email-icon'/><span>tech@metamorph-sd.com</span></div>
            <div><img className="location-icon" src={locationIcon} alt='location-icon'/><span>{translate[lang].footer.address}</span></div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright © 2024 MSD</p>
        <div className="social-links">
          <Link to={'https://www.linkedin.com/company/metamorph-system-development/'}><img src={linkedinIcon} alt='call-icon'/></Link>
          <Link to={'https://www.facebook.com/MetamorphSysDev'}><img src={fbIcon} alt='call-icon'/></Link>
          <Link to={'https://www.instagram.com/metamorphsystemdevelopment/'}><img src={instaIcon} alt='call-icon'/></Link>
        </div>
      </div>
    </div>
  )
}

export default Footer