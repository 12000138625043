import translate from './../../services/translation.json';
import './getInTouch.scss';
import getInTouchIcon from './../../assets/images/get_in_touch_image.png';

const GetInTouch = ({lang}) => {
  return (
    <div className="get-in-touch mw-1200" style={{background: `url(${getInTouchIcon})`, backgroundSize:'cover'}}>
          <h1>
            {translate[lang].homepage.get_in_touch_title_white} <br/>
            <span className="blue">{translate[lang].homepage.get_in_touch_title_blue}</span>
          </h1>
          <a href="/contact-us" className="contact-btn">
          {translate[lang].homepage.get_in_touch_btn}
        </a>
        </div>
  )
}

export default GetInTouch;