import translate from "./../services/translation.json";
import './../assets/scss/home.scss';
import GetInTouch from "../components/getInTouch/getInTouch";
import OurClients from "../components/ourClients/ourClients";
import home_banner from './../assets/images/home_banner.png';
import webImage from './../assets/images/web_app.png';
import mobileImage from './../assets/images/mobile_app.png';
import cloudImage from './../assets/images/cloud_services.png';
import uiImage from './../assets/images/ui_design.png';

import productImage from './../assets/images/product_image.png';
import creativityIcon from './../assets/images/creativity_icon.svg';
import teamworkIcon from './../assets/images/teamwork_icon.svg';
import innovationIcon from './../assets/images/innovation_icon.svg';
import qualityIcon from './../assets/images/quality_icon.svg';
import simplicityIcon from './../assets/images/simplicity_icon.svg';
import integrityIcon from './../assets/images/integrity_icon.svg';

const Home = ({lang}) => {

  const servicesData = [
    {
      image:webImage,
      title:translate[lang].homepage.services.web_app_title,
      desc:translate[lang].homepage.services.web_app_desc
    },{
      image:mobileImage,
      title:translate[lang].homepage.services.mobile_app_title,
      desc:translate[lang].homepage.services.mobile_app_desc
    },{
      image:cloudImage,
      title:translate[lang].homepage.services.cloud_title,
      desc:translate[lang].homepage.services.cloud_desc
    },{
      image:uiImage,
      title:translate[lang].homepage.services.ui_title,
      desc:translate[lang].homepage.services.ui_desc
    }
  ];

  const coreValuesData = [
    {
      image:creativityIcon,
      title:translate[lang].homepage.core_values.creativity_title,
      desc:translate[lang].homepage.core_values.creativity_desc
    },
    {
      image:teamworkIcon,
      title:translate[lang].homepage.core_values.teamwork_title,
      desc:translate[lang].homepage.core_values.teamwork_desc
    },
    {
      image:innovationIcon,
      title:translate[lang].homepage.core_values.innovation_title,
      desc:translate[lang].homepage.core_values.innovation_desc
    },
    {
      image:qualityIcon,
      title:translate[lang].homepage.core_values.quality_title,
      desc:translate[lang].homepage.core_values.quality_desc
    },
    {
      image:simplicityIcon,
      title:translate[lang].homepage.core_values.simplicity_title,
      desc:translate[lang].homepage.core_values.simplicity_desc
    },
    {
      image:integrityIcon,
      title:translate[lang].homepage.core_values.integrity_title,
      desc:translate[lang].homepage.core_values.integrity_desc
    },
  ]

  return (
    <div className="homepage page-container">
      <div className="homepage-banner" style={{backgroundImage: `url(${home_banner})`}}>
        <h1 className="homepage-title">
          {translate[lang].homepage.banner_title_engineering} <span className="blue">{translate[lang].homepage.banner_title_digital}</span>
        </h1>
        <p className="homepage-subtitle">
          {translate[lang].homepage.banner_subtitle}
        </p>
        <a href="#page_content" className="banner-btn">
          {translate[lang].homepage.banner_button}
        </a>
      </div>
      <div className="homepage_content" id="page_content">
        <div className='homepage-services mw-1200'>
          <h1>
            {translate[lang].homepage.our_services}
          </h1>
          <div className='services-container'>
            {servicesData.map((service,i)=>{
              return <div className="service" key={`service-${i}`}>
                  <img src={service.image} alt={`${service.title}-img`}/>
                  <h2 className="service-title">
                    {service.title}
                  </h2>
                  <p className="service-desc">
                  {service.desc}
                  </p>
              </div>
            })}
          </div>
        </div>
        <OurClients lang={lang}/>
        <div className="product-lifecycle mw-1200">
          <h1>
            {translate[lang].homepage.product_lifecycle}
          </h1>
          <img src={productImage} alt="product-lifecycle-image"/>
        </div>
        <div className="our-values">
          <h1>
            {translate[lang].homepage.our_values}
          </h1>
          <div className="values-container">
          {coreValuesData.map((value,i)=>{
              return <div className="value" key={`value-${i}`}>
                  <img src={value.image} alt={`${value.title}-img`}/>
                  <h2 className="value-title">
                    {value.title}
                  </h2>
                  <p className="value-desc">
                  {value.desc}
                  </p>
              </div>
            })}
          </div>
        </div>
        <GetInTouch lang={lang}/>
      </div>
    </div>
  )
}

export default Home;