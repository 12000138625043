import translate from './../../services/translation.json';
import './ourClients.scss';
import nolatoLogo from './../../assets/images/nolato.png';
import edelwealthLogo from './../../assets/images/edelwealth.png';
import wohnLogo from './../../assets/images/wohn.png';

const OurClients = ({lang}) => {
  return(
    <div className="our-clients contain mw-1200">
          <h1>
            {translate[lang].homepage.our_clients}
          </h1>
          <div className="our-clients-logos">
            <div className="logo-container">
              <img src={nolatoLogo} alt=""/>
            </div>
            <div className="logo-container">
              <img src={edelwealthLogo} alt=""/>
            </div>
            <div className="logo-container">
              <img src={wohnLogo} alt=""/>
            </div>
          </div>
        </div>
  ) 
}

export default OurClients;