import { useState } from 'react';
import {useLocation,Link} from 'react-router-dom';
import coloredLogo from '../../assets/images/Logo Colored.svg';
import translate from "../../services/translation.json";
import './header.scss';
import menuIcon from './../../assets/images/menu_icon.svg'
const Header = ({lang, setLang}) => {

  const pageUrls = ['about-us', 'services', 'contact-us']
  const location = useLocation()
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [openMobileMenuClassName, setOpenMobileMenuClassName] = useState("");

  const renderNav = (isDesktop) => {
    return <>
      <div className={`nav-bar ${isDesktop? 'desktop': ''}`} >
      {pageUrls.map((url) => (
        <Link onClick={() => !isDesktop && handleMobileNavigationOpen(false)}  to={url} key={`desktop-header-${url}`} className={`header-link ${location.pathname===`/${url}`? 'active': ''}`}>
            {translate[lang].header[url]}
        </Link>
      ))}
      </div>
      <div className={`lang-selector ${isDesktop? 'desktop': ''}`}>
        <span className={`english-lang ${lang==='en'? 'active': ''}`} onClick={()=>setLang('en')}>En</span>
        |
        <span className={`dutch-lang ${lang==='de'? 'active': ''}`} onClick={()=>setLang('de')}>De</span>
      </div>
    </>
  }

  // --- handle mobile navigation
  const handleMobileNavigationOpen = (isOpen) => {
    if (isOpen) {
      setIsOpenMobileMenu(isOpen);
      setTimeout(() => {
        setOpenMobileMenuClassName("open");
      }, 50);
    } else {
      setOpenMobileMenuClassName("");
      setTimeout(() => {
        setIsOpenMobileMenu(false);
      }, 500);
    }
  };

  const renderMobileNavigation = () => {
    return (
      <>
      <div className="header-mobile-nav">
        <img
          onClick={() => handleMobileNavigationOpen(true)}
          alt="open mobile navigation menu"
          src={menuIcon}
        />
        {isOpenMobileMenu && (
          <div
            className={`header-mobile-nav-drawer ${openMobileMenuClassName}`}
          >
            <div
              className="header-mobile-nav-drawer-drop"
              onClick={() => handleMobileNavigationOpen(false)}
            ></div>
            <div className="header-mobile-nav-drawer-wrapper">
              <div className="header-mobile-nav-drawer-wrapper-content">
                <Link onClick={() => handleMobileNavigationOpen(false)} to={'/'} className='logo-wrapper'>
                  <img src={coloredLogo} alt='logo'/>
                </Link>
                {renderNav(false)}
              </div>
            </div>
          </div>
        )}
      </div>
      </>
    );
  };

  return(
    <div className="header-container">
      <Link to={'/'} className='logo-wrapper'>
        <img src={coloredLogo} alt='logo'/>
      </Link>
      {renderNav(true)}
      <div className='mobile'>{renderMobileNavigation()}</div>
    </div>
  )
}

export default Header;