import translate from './../services/translation.json';
import { Link } from "react-router-dom";
import './../assets/scss/contactUs.scss';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import callIcon from './../assets/images/blue-call-icon.svg';
import emailIcon from './../assets/images/blue-email.svg';
import locationIcon from './../assets/images/blue-location.svg';
import fbIcon from './../assets/images/fb-blue.svg';
import instaIcon from './../assets/images/blue-insta.svg';
import linkedinIcon from './../assets/images/linkedin-blue.svg';

const ContactUs = ({lang}) => {
  return (
    <div className="contact-us page-container contain">
      <h1 className='blue center title'>
        {translate[lang].contact_page.title}
      </h1>
      <p className='center purple subtitle'><i>{translate[lang].contact_page.subtitle}</i></p>
      <div className='contact-us-container'>
        <div className='contact-us-container-form padded-container'>
          <TextField id="outlined-basic" label={translate[lang].contact_page.form.name} variant="outlined" />
          <TextField id="outlined-basic" label={translate[lang].contact_page.form.email} variant="outlined" />
          <TextField
            label={translate[lang].contact_page.form.msg}
            multiline
            rows={4} // Number of visible rows
            variant="outlined"
          />
          <Button color="primary" variant="contained" style={{width:"80%"}}>{translate[lang].contact_page.form.submit}</Button>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className='contact-us-container-info'>
          <h2 className='blue title'>
            {translate[lang].contact_page.title}
          </h2>
          <div className="contact-details">
            <div><img src={callIcon} alt='call-icon'/><span>+41 78 793 79 83</span></div>
            <div><img src={emailIcon} alt='email-icon'/><span>tech@metamorph-sd.com</span></div>
            <div><img className="location-icon" src={locationIcon} alt='location-icon'/><span>{translate[lang].footer.address}</span></div>
          </div>
          <div className='follow-us'>
            <p>{translate[lang].contact_page.follow}:</p>
            <div className="social-links">
              <Link to={'https://www.linkedin.com/company/metamorph-system-development/'}><img src={linkedinIcon} alt='call-icon'/></Link>
              <Link to={'https://www.facebook.com/MetamorphSysDev'}><img src={fbIcon} alt='call-icon'/></Link>
              <Link to={'https://www.instagram.com/metamorphsystemdevelopment/'}><img src={instaIcon} alt='call-icon'/></Link>
            </div>
          </div>
          
        </div>
      </div>      
    </div>
  )
}

export default ContactUs