import translate from './../services/translation.json';
import './../assets/scss/services.scss';
import { Polygon } from '../components/polygon/polygon';
import webDevImg from './../assets/images/webDev.png';
import mobileDevImg from './../assets/images/mobileDev.png';
import uiImg from './../assets/images/UI.png';
import cloudImg from './../assets/images/cloud.png';
const Services = ({lang}) => {

  const renderWebDevSection = () => {
    return (
      <div className='web-dev'>
        <h1 className='blue center title'>{translate[lang].services.web_dev.title}</h1>
        <div className='web-dev-desc padded-container'>
          <p>
            {translate[lang].services.web_dev.desc}
          </p>
          <div className='img-wrapper'>
            <img src={webDevImg} alt="Web Development" />
          </div>
        </div>
        <div className='polygon-container'>
          <Polygon text={translate[lang].services.web_dev.insights}/>
          <Polygon text={translate[lang].services.web_dev.starting_from}/>
          <Polygon text={translate[lang].services.web_dev.refactor}/>
        </div>
      </div>
    )
  }

  const renderMobileDevSection = () => {
    return (
      <div className='mobile-dev'>
        <h1 className='blue center title'>{translate[lang].services.mobile_dev.title}</h1>
        <div className='mobile-dev-desc padded-container'>
          <p>
            {translate[lang].services.mobile_dev.desc}
          </p>
        </div>
        <div className='polygon-container'>
          <div className='polygon-wrapper'>
            <div className='polygon-container-top'>
              <Polygon text={translate[lang].services.mobile_dev.business}/>
              <Polygon text={translate[lang].services.mobile_dev.customized}/>
            </div>
            <div className='polygon-container-bottom'>
              <Polygon text={translate[lang].services.mobile_dev.startups}/>
              <Polygon text={translate[lang].services.mobile_dev.happy}/>
            </div>
          </div>
          <div className='img-wrapper'>
            <img src={mobileDevImg} alt="Mobile Development" />
          </div>
        </div>
      </div>
    )
  }

  const renderUiSection = () => {
    return (
      <div className='ui'>
        <h1 className='blue center title'>{translate[lang].services.ui.title}</h1>
        <div className='ui-desc padded-container'>
          <p>
            {translate[lang].services.ui.desc}
          </p>
        </div>
        <div className='polygon-container'>
          <div className='polygon-wrapper'>
            <div className='polygon-container-top'>
              <Polygon text={translate[lang].services.ui.research}/>
              <Polygon text={translate[lang].services.ui.planning}/>
            </div>
            <div className='polygon-container-center'>
              <Polygon text={translate[lang].services.ui.designing}/>
              <Polygon text={translate[lang].services.ui.content}/>
            </div>
            <div className='polygon-container-bottom'>
              <Polygon text={translate[lang].services.ui.development}/>
              <Polygon text={translate[lang].services.ui.testing}/>
            </div>
          </div>
          <div className='img-wrapper'>
            <img src={uiImg} alt="UI/UX" />
          </div>
        </div>
      </div>
    )
  }

  const renderCloudSection = () => {
    return (
      <div className='cloud'>
        <h1 className='blue center title'>{translate[lang].services.cloud.title}</h1>
        <p className='center subtitle'>{translate[lang].services.cloud.subtitle}</p>
        <div className='cloud-desc padded-container'>
          <p>
            {translate[lang].services.cloud.desc}
          </p>
          <div className='img-wrapper'>
            <img src={cloudImg} alt="Cloud Computing" />
          </div>
        </div>
        <div className='polygon-container'>
          <Polygon text={translate[lang].services.cloud.infrastructure}/>
          <Polygon text={translate[lang].services.cloud.platform}/>
          <Polygon text={translate[lang].services.cloud.software}/>
        </div>
      </div>
    )
  }



  return (
    <div className="page-container contain services">
        {renderWebDevSection()}
        {renderMobileDevSection()}
        {renderUiSection()}
        {renderCloudSection()}
    </div>
  )
}

export default Services