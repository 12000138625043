import './polygon.scss';
import polygonBg from './../../assets/images/PolygonBg.svg';

export const Polygon = ({text}) => {
  return (
    <div className='polygon' style={{backgroundImage:`url(${polygonBg})` }}>
      <p>
        {text}
      </p>
    </div>
  )
}
